<template>
    <div class="page-main-wrapper">
        <div class="container">

            <div class="text-center">
                <div class="mt-5 mb-5">
                    <img src="../assets/blues_logo.png" alt="">
                </div>
            </div>

            <div class="membership-content text-light-gray p-5">
                <p><strong>Lütfen bu sayfada yer alan tüm bilgileri dikkatlice okuyunuz.</strong></p>

                <p><strong>Blues Derneği üyeliği için gerekli koşullar</strong></p>
                <ul>
                    <li>18 yaşını doldurmuş olmak</li>
                    <li>Derneklere üye olma hakkı sürekli veya süreli olarak kısıtlanmamış olmak</li>
                    <li>Derneğin amaç ve ilkelerini benimseyerek bu doğrultuda çalışmayı kabul etmek</li>
                    <li>Yabancı uyruklu kişiler için Türkiye’de yerleşme hakkına sahip olmak</li>
                    <li>Blues Derneği tüzüğünü okumuş olmak</li>
                    <li>Üyelik için gerekli belgeleri eksiksiz sağlayarak, giriş ödentisi ve ilk yıllık üyelik aidatını ödeyerek üyelik başvurusunu tamamlamak.</li>
                </ul>

                <p><strong>Üyelik başvurusu için gerekli belgeler:</strong></p>
                <ol>
                    <li>Üyelik başvuru formu (formu doldurmak için aşağıdaki Blues Derneği Üyelik Başvuru Formu linkine tıklayınız)</li>
                    <li>Vesikalık fotoğraf</li>
                    <li>
                        2024 yılı için giriş ödentisi olan 240 TL ve 2024 yılı için yıllık aidat miktarı olan 240 TL’nin (toplam 480 TL) aşağıdaki Blues Derneği hesabına yatırıldığına dair dekont (açıklama kısmında isim-soyisim ve hangi yılın giriş ödentisi ve yıllık aidatı olduğunu belirtiniz. Örnek: Ayşe Mavi 2024 yılı giriş ödentisi ve üyelik aidatı).
                        <br>Blues Derneği - Ziraat Bankası
                        <br>IBAN: TR74 0001 0006 7992 0184 5250 01
                    </li>
                </ol>

                <p><strong>Önemli not: Giriş ödentisi Blues Derneği’ne ilk üyelik başvurusu sırasında bir defalığına alınmaktadır. Üyelik aidatları ise üyelik başvurusunun yapıldığı tarihten bağımsız, takvim yılına göre, YILLIK olarak alınmaktadır. Örneğin Kasım 2024’te üyeliği başlayan bir üyemiz 2024 yılı aidatını ödemiş olur ve Ocak 2025’te 2025 yılı aidatını ödemesi gerekmektedir. Üyelikten ayrılma talepleri yazılı olarak yönetim kuruluna ulaşmayan veya e-devlet üzerinden üyelikten çıkma işlemi yapmayan üyelerin üyelikleri her yıl Ocak ayının 1. günü otomatik olarak yenilenmektedir. Lütfen üyeliğe giriş ve üyelikten çıkış için göz önünde bulundurunuz.</strong></p>

                <p>
                    <a target="_blank" href="https://dosyalar.bluesdernegi.org/Blues-Dernegi-Tuzugu_12.12.2021.pdf">
                        Blues Derneği tüzüğünü okumak için tıklayınız.
                    </a>
                </p>

                <p>
                    <a target="_blank" href="https://dosyalar.bluesdernegi.org/Blues-Dernegi_KVKK_Aydinlatma_Metni.pdf">
                        “Kişisel Verilerin Korunması Kanunu Aydınlatma Metni”ni okumak için tıklayınız.
                    </a>
                </p>

                <p><strong>Üyelikten çıkma (Blues Derneği Tüzüğü Madde 4)</strong></p>
                <p>Her üye yazılı olarak bildirmek kaydıyla, dernekten çıkma hakkına sahiptir.</p>
                <p>Üyenin istifa dilekçesi yönetim kuruluna ulaştığı anda çıkış işlemleri sonuçlanmış sayılır.</p>
                <p>Üyenin, üyelikten ayrılması derneğe üyelikten kaynaklanan muaccel olmuş veya ileride muaccel olacak borçlarını sona erdirmeyecek olup, üyelikten çıkma talebinin bildirim tarihi itibari ile tüm borçlarını ödemesi gerekmektedir.</p>

                <p><strong>Üyelikten çıkarılma (Blues Derneği Tüzüğü Madde 5)</strong></p>
                <p>Dernek üyeliğinden çıkarılmayı gerektiren haller</p>
                <ol>
                    <li>Dernek tüzüğüne aykırı davranışlarda bulunmak,</li>
                    <li>Verilen görevlerden sürekli kaçınmak,</li>
                    <li>E-posta, whatsapp, sms, iadeli taahhütlü posta veya benzeri yazılı yollarla en fazla  30 (otuz) gün arayla yapılacak iki haklı ikaza rağmen üyelik aidatını ikinci ikazdan itibaren 30 (otuz) içinde ödememek,</li>
                    <li>Dernek organlarınca verilen kararlara uymamak,</li>
                    <li>Üye olma şartlarını kaybetmiş olmak,</li>
                    <li>Derneği maddi veya manevi zarara sokacak davranışlarda bulunmak,</li>
                    <li>Derneğin amacını gerçekleştirmesini engelleyici ve zorlaştırıcı işlem ve eylemlerde bulunmak,</li>
                    <li>Yüz kızartıcı suçlardan birine ilişkin kesinleşmiş mahkumiyet kararının olması,</li>
                    <li>Yönetim kurulunun bilgisi dışında dernek adını kullanarak temas ve faaliyetlerde bulunmak</li>
                </ol>

                <p>Yukarıda sayılan durumlardan birinin tespiti halinde üye yönetim kurulu kararı ile üyelikten çıkarılabilir.</p>

                <p>Dernekten çıkan veya çıkarılanlar, üye kayıt defterinden silinir ve dernek malvarlığında hak iddia edemez. </p>

                <p class="text-center mt-5">
                    <a target="_blank" class="google-form-link" href="https://docs.google.com/forms/d/1blg9mAXgzI6iSzXn_5F04YrfHenK38RcvbxbUsMxREg/edit">
                        Blues Derneği Üyelik Başvuru Formu’nu doldurmak için tıklayınız
                    </a>
                </p>

            </div>
            
            <div class="pt-5">&nbsp;</div>

        </div>
    </div>
</template>

<style lang="scss" scoped>
    .page-main-wrapper {
        height: calc(100% - 120px);
        font-family: 'Open Sans', sans-serif;
    }

    .membership-content {
        background: rgba(42, 42, 42, 0.65);
        border: 1px solid #636363;
    }

    .text-light-gray {
        color: #aeaeae;

        strong {
            color: #dbdbdb;
        }
    }

    .google-form-link {
        font-size: 18px;
        color: #d4d4d4;
        background-color: #003154;
        border: 1px solid #002241;
        padding: 4px 50px;

        -webkit-transition: all 200ms ease-in-out;
        -moz-transition: all 200ms ease-in-out;
        -o-transition: all 200ms ease-in-out;
        transition: all 200ms ease-in-out;

        line-height: 40px;
        display: inline-block;

        &:hover {
            text-decoration: none;
            background-color: #005685;
        }
    }
</style>

<script>
    export default {
        name: "membership",
        mounted() {
            this.$appBgImage = '/bg/background_web_membership.jpg';
        }
    }
</script>
