<template>
    <div class="text-center mt-5">
        <h3>
            {{ $t("not_found.message") }}
        </h3>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    export default {
        name: "not-found",
        mounted() {
            this.$appBgImage = this.defaultApiBgImage;
        }
    }
</script>