<template>
    <div class="container-fluid">

        <div class="d-none d-lg-block">
            <div class="about-page-menu d-flex justify-content-center">

                <a href="javascript:void(0)" v-bind:class="{active: currentTab === 1}" @click="currentTab = 1">
                    {{ $t("about.our_mission_and_vision") }}
                </a>
                <span>|</span>
                <a href="javascript:void(0)" v-bind:class="{active: currentTab === 3}" @click="currentTab = 3">
                    {{ $t("about.our_statute") }}
                </a>
                <span>|</span>
                <a href="javascript:void(0)" v-bind:class="{active: currentTab === 4}" @click="currentTab = 4">
                    {{ $t("about.board_of_directors") }}
                </a>
                <span>|</span>
                <a href="javascript:void(0)" v-bind:class="{active: currentTab === 5}" @click="currentTab = 5">
                    {{ $t("about.annual_reports") }}
                </a>
            </div>
        </div>

        <div class="d-block d-lg-none about-page-menu2 text-center mt-3">
            <a href="javascript:void(0)" v-bind:class="{active: currentTab === 1}" @click="currentTab = 1">
                {{ $t("about.our_mission_and_vision") }}
            </a>

            <div class="mt-2"></div>

            <a href="javascript:void(0)" v-bind:class="{active: currentTab === 3}" @click="currentTab = 3">
                {{ $t("about.our_statute") }}
            </a>

            <span>|</span>

            <a href="javascript:void(0)" v-bind:class="{active: currentTab === 4}" @click="currentTab = 4">
                {{ $t("about.board_of_directors") }}
            </a>

            <div class="mt-2"></div>

            <a href="javascript:void(0)" v-bind:class="{active: currentTab === 5}" @click="currentTab = 5">
                {{ $t("about.annual_reports") }}
            </a>
        </div>

        <div v-if="currentTab === 1" class="row mt-3 text-center text-light-gray text-misyon-vizyon">
            <div class="col-12 col-lg-6 offset-lg-3">
                <p style="font-size:28px; font-weight: bold;">{{ $t("about.our_mission") }}</p>
                <p>- {{ $t("about.our_mission_1") }}</p>
                <p>- {{ $t("about.our_mission_2") }}</p>
                <p>- {{ $t("about.our_mission_3") }}</p>
                <p>- {{ $t("about.our_mission_4") }}</p>
                <p>- {{ $t("about.our_mission_5") }}</p>

                <p style="font-size: 28px; font-weight: bold;" class="mt-3">{{ $t("about.our_vision") }}</p>

                <p>
                    {{ $t("about.our_vision_1") }}
                </p>
            </div>
        </div>

        <div v-if="currentTab === 3" class="row mt-3 text-center text-light-gray">
            <div class="col-12 col-lg-6 offset-lg-3">
                <p class="mt-5">
                    <a class="blues-link" target="_blank" href="https://dosyalar.bluesdernegi.org/Blues-Dernegi-Tuzugu_12.12.2021.pdf">
                        Blues Derneği tüzüğünü okumak için tıklayınız.
                    </a>
                </p>
            </div>
        </div>

        <div v-if="currentTab === 4" class="row mt-3">
            <div class="col-12 col-lg-6 offset-lg-3">

                <div class="founders">

                    <div class="b-custom-row">
                        <div class="b-custom-column text-center" v-for="director in directors" v-bind:key="director.id">
                            <a href="javascript:void(0)" @click="currentDirector = director"
                               :class="{ 'active2': currentDirector === director }">
                                {{ director.musician.name }} {{ director.musician.surname }}
                            </a>
                        </div>
                    </div>

                </div>

                <div class="founder mt-2" v-if="currentDirector">

                    <div class="row">

                        <div class="col-12 col-lg-3">
                            <img :src="
                            (
                            typeof currentDirector.musician.profile_picture === 'undefined' ||
                            currentDirector.musician.profile_picture === null
                            ) ?
                            '/demo/musician_default.png' : currentDirector.musician.profile_picture" alt=""
                                 class="director-profile-picture">
                        </div>

                        <div class="col-12 col-lg-9">
                            <h2>{{ currentDirector.musician.name }} {{ currentDirector.musician.surname }}</h2>

                            <p class="mb-2" style="color: #ffc103" v-if="currentDirector.musician.surname">
                                {{ currentDirector.role }}
                            </p>

                            <p class="mb-0" v-if="currentDirector.musician.surname">
                                {{ $t("musician.date_of_birth") }} {{ (new Date(currentDirector.musician.birthdate)).getFullYear() }}
                            </p>

                            <p class="mb-0" v-if="currentDirector.musician.start_date_to_music">
                                {{ $t("musician.active_years") }} {{ (new Date(currentDirector.musician.start_date_to_music)).getFullYear()
                                }} -
                                {{ currentDirector.musician.is_continue ?
                                $t("musician.present") :
                                (new Date(currentDirector.musician.finish_date_to_music)).getFullYear() }}
                            </p>
                            <p v-else class="mb-0">
                                {{ $t("musician.active_years") }} {{ (new Date(currentDirector.musician.birthdate)).getFullYear() }} -
                                {{ currentDirector.musician.is_continue ?
                                $t("musician.present") :
                                (new Date(currentDirector.musician.finish_date_to_music)).getFullYear() }}
                            </p>

                            <p class="mb-0" v-if="currentDirector.musician.instruments">
                                {{ $t("musician.instruments") }} {{ currentDirector.musician.instruments.join(', ') }}
                            </p>

                            <p class="mb-0">
                                {{ $t("musician.genre") }} {{ currentDirector.musician.genre.join(', ') }}
                            </p>

                            <p class="mb-0 mt-3">{{ $t("musician.compositions_and_lyrics") }}</p>
                            <p class="mb-3">{{ $t("generic.very_soon") }}</p>

<!--                            <p class="mb-0" v-if="currentDirector.workedwith && currentDirector.workedwith.length > 0">-->
<!--                                Birlikte çalıştığı müzisyenler ve gruplar:-->
<!--                            </p>-->
                            <p class="mb-0" v-if="currentDirector.workedwith && currentDirector.workedwith.length > 0">
                                <span v-for="person in currentDirector.workedwith" :key="person.id">
<!--                                    {{ person.name }}{{ index !== (currentDirector.workedwith.length-1) ? ',' : '' }}-->
                                    <div v-html="person.note"></div>
                                </span>
                            </p>

                        </div>

                    </div>

                </div>

            </div>
        </div>

               
        <div v-if="currentTab === 5" class="row mt-3 text-center text-light-gray">
<!-- Faaliyet Raporu Başlangıç -->
            
            <div class="col-12 col-lg-6 offset-lg-3">

                <div class="mt-1 mb-3">
                    <p>
                        > 2022 Faaliyet Raporu
                        ( <a href="/dosyalar/Blues Dernegi_2022 Faaliyet Raporu.pdf" target="_blank">
                            Görüntüle
                        </a>
                        -
                        <a href="/dosyalar/Blues Dernegi_2022 Faaliyet Raporu.pdf" download="Blues Dernegi_2022 Faaliyet Raporu.pdf">
                            İndir
                        </a> )
                    </p>
                </div>

                <div class="embed-responsive embed-responsive-1by1">
                    <iframe class="embed-responsive-item" src="/dosyalar/Blues Dernegi_2022 Faaliyet Raporu.pdf"></iframe>
                </div>

            </div>

            <div class="col-12 col-lg-6 offset-lg-3">

                <div class="mt-1 mb-3">
                    <p>
                        > 2020 – 2021 Pandemi Dönemi Faaliyet Raporu
                        ( <a href="/dosyalar/Blues-Dernegi_2020-2021_Pandemi Donemi Faaliyet Raporu.pdf" target="_blank">
                            Görüntüle
                        </a>
                        -
                        <a href="/dosyalar/Blues-Dernegi_2020-2021_Pandemi Donemi Faaliyet Raporu.pdf" download="Blues-Dernegi_Mayıs2018-Aralık2019_Faaliyet-Raporu.pdf">
                            İndir
                        </a> )
                    </p>
                </div>

                <div class="embed-responsive embed-responsive-1by1">
                    <iframe class="embed-responsive-item" src="/dosyalar/Blues-Dernegi_2020-2021_Pandemi Donemi Faaliyet Raporu.pdf"></iframe>
                </div>

            </div>

            <div class="col-12 col-lg-6 offset-lg-3">

                <div class="mt-1 mb-3">
                    <p>
                        > Mayıs 2018 – Aralık 2019 Faaliyet Raporu
                        ( <a href="/dosyalar/Blues-Dernegi_Mayıs2018-Aralık2019_Faaliyet-Raporu.pdf" target="_blank">
                            Görüntüle
                        </a>
                        -
                        <a href="/dosyalar/Blues-Dernegi_Mayıs2018-Aralık2019_Faaliyet-Raporu.pdf" download="Blues-Dernegi_Mayıs2018-Aralık2019_Faaliyet-Raporu.pdf">
                            İndir
                        </a> )
                    </p>
                </div>

                <div class="embed-responsive embed-responsive-1by1">
                    <iframe class="embed-responsive-item" src="/dosyalar/Blues-Dernegi_Mayıs2018-Aralık2019_Faaliyet-Raporu.pdf"></iframe>
                </div>

            </div>
        </div>

    </div>
</template>

<style lang="scss" scoped>
    $grid-breakpoints: (
        // Extra small screen / phone
            xs: 0,
        // Small screen / phone
            sm: 576px,
        // Medium screen / tablet
            md: 768px,
        // Large screen / desktop
            lg: 992px,
        // Extra large screen / wide desktop
            xl: 1200px
    );

    a {
        text-decoration: none;
        color: #ffffff;

        &:hover {
            color: #ffc103;
        }

        &.active {
            color: #ffc103;
            border-bottom: 1px solid #ffc103;
        }
    }

    .b-custom-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;

        .b-custom-column {
            display: flex;
            flex-direction: column;
            flex-basis: 100%;
            flex: 0 0 50%;

            @media screen and (min-width: map-get($grid-breakpoints, md)) {
                flex: 1;
            }
        }
    }

    .about-page-menu {
        margin-top: 12rem !important;

        span {
            margin-left: 20px;
            margin-right: 20px;
        }
    }

    .about-page-menu2 {
        span {
            padding-bottom: 10px;
            margin-left: 5px;
            margin-right: 5px;
        }
    }

    .text-light-gray {
        color: #aeaeae;
    }

    .founders {
        background: rgba(0, 0, 0, 0.7);
        border: 1px solid #1d1d1d;
        padding: 10px;
    }

    .founder {
        padding: 10px;
        background: rgba(42, 42, 42, 0.65);
        border: 1px solid #636363;

        h2 {
            color: #ffc103;
        }

        p {
            color: #e0e0e0;
        }
    }

    .tuzuk-content {
        height: 600px;
        overflow: auto;
        background: rgba(42, 42, 42, 0.65);
        border: 1px solid #636363;
        padding: 15px;
    }

    .text-misyon-vizyon {
        font-size: 1.1rem;

        p {
            margin-bottom: 0;
        }
    }

    .director-profile-picture {
        width: 100%;
        padding: 5px 1px 5px 5px;
    }

    .active2 {
        color: #ffc103;
    }

    .blues-link {
        font-size: 18px;
        color: #d4d4d4 !important;
        background-color: #003154;
        border: 1px solid #002241;
        padding: 4px 50px;

        -webkit-transition: all 200ms ease-in-out;
        -moz-transition: all 200ms ease-in-out;
        -o-transition: all 200ms ease-in-out;
        transition: all 200ms ease-in-out;

        line-height: 40px;
        display: inline-block;

        &:hover {
            text-decoration: none;
            background-color: #005685;
        }
    }
</style>

<script>
    import axios from 'axios';

    export default {
        name: 'about',
        mounted() {
            this.$appBgImage = '/bg/background_web_calendar.jpg';

            if (typeof this.$route.meta.tab !== 'undefined') {
                this.currentTab = this.$route.meta.tab;
            }

            axios
                .get(this.apiBaseUrl + 'team/?format=json')
                .then(response => {
                    this.directors = response.data;

                    if (this.directors.length > 0) {
                        for (let i = 0; i < this.directors.length; i++) {
                            axios
                                .get(this.apiBaseUrl + 'musician/workedwith/' + this.directors[i].musician.id + '?format=json')
                                .then(response2 => {
                                    this.directors[i].workedwith = response2.data;
                                    // this.directors[i].workedwith[0].note = this.directors[i].workedwith[0].note.replace('ÇALIŞTIĞI MÜZİSYENLER VE GRUPLAR:', '').trim();
                                    // this.directors[i].workedwith[0].note = this.directors[i].workedwith[0].note.replace(/(?:\r\n|\r|\n)/g, '<br />');
                                    this.$forceUpdate();
                                });
                        }
                    }

                    this.currentDirector = this.directors[0];
                });
        },
        data: function () {
            return {
                currentTab: 1,
                founders: [],
                directors: [],
                currentFounder: null,
                currentDirector: null,
            }
        },
        methods: {
            triggerTab: function () {
                switch (this.currentTab) {
                    case 1:
                        if ('about_mission_and_vision' !== this.$route.name) {
                            this.$router.push({name: 'about_mission_and_vision' + ('en' === this.$route.params.language ? '_en' : ''), params: { language: this.$i18n.locale }});
                        }
                        break;
                    case 3:
                        if ('about_charter' !== this.$route.name) {
                            this.$router.push({name: 'about_charter' + ('en' === this.$route.params.language ? '_en' : ''), params: { language: this.$i18n.locale }});
                        }
                        break;
                    case 4:
                        if ('about_board_of_directors' !== this.$route.name) {
                            this.$router.push({name: 'about_board_of_directors' + ('en' === this.$route.params.language ? '_en' : ''), params: { language: this.$i18n.locale }});
                        }
                        break;
                    case 5:
                        if ('about_annual_reports' !== this.$route.name) {
                            this.$router.push({name: 'about_annual_reports' + ('en' === this.$route.params.language ? '_en' : ''), params: { language: this.$i18n.locale }});
                        }
                        break;
                }
            }
        },
        watch: {
            currentTab: {
                handler() {
                    this.triggerTab();
                }
            }
        }
    }
</script>
