<template>
    <div class="container">
        <div class="text-center w-100 mt-5" v-if="!items">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <div class="event-list-arrows" v-if="items">

            <div class="d-none d-lg-block event-list-arrow event-list-arrow-left"
                 :class="{ 'event-button-disabled': !checkPreviousButton() }"
                 @click="previousEvent()"
            >
                <img src="../assets/arrow_left.png" alt="">
            </div>

            <div class="row mt-4">
                <div v-for="event in fourEvents"
                     v-bind:key="event.id"
                     @click="currentEvent = event"
                     class="col-6 col-lg-3"
                     style="padding: 0"
                >
                    <div class="single-event text-center" :class="{active: currentEvent.id === event.id}">
                        <img :src="event.poster" alt="">
                    </div>
                </div>
            </div>

            <div class="d-none d-lg-block event-list-arrow event-list-arrow-right"
                 :class="{ 'event-button-disabled': !checkNextButton() }"
                 @click="nextEvent()"
            >
                <img src="../assets/arrow_right.png" alt="">
            </div>

            <div class="d-block d-lg-none">
                <div class="row">
                    <div class="col-6" style="padding: 4px;">
                        <button style="background-color: #3690ba; width: 100%; border: none; padding: 4px;"
                                :class="{ 'event-button-disabled': !checkPreviousButton() }"
                                @click="previousEvent()"
                        >
                            <img src="../assets/arrow_left.png" alt="">
                        </button>
                    </div>
                    <div class="col-6" style="padding: 4px;">
                        <button style="background-color: #3690ba; width: 100%; border: none; padding: 4px;"
                                :class="{ 'event-button-disabled': !checkNextButton() }"
                                @click="nextEvent()"
                        >
                            <img src="../assets/arrow_right.png" alt="">
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="currentEvent" class="row mt-2 single-event-info">
            <div class="col-12 col-lg-3 text-center">
                <img :src="currentEvent.poster" class="w-100 mt-3 mb-3" alt="">
            </div>
            <div class="col-12 col-lg-9">
                <h2 class="mt-3">{{ currentEvent.title }}</h2>

                <p class="mb-2" v-html="currentEvent.description"></p>

                <div class="mt-4 mb-4">
                    <a :href="currentEvent.registration_url" target="_blank">{{ $t("events.click_for_more_info") }}</a>
                </div>

                <div class="single-event-info-icons mt-3 mb-2 text-white">
                    <div>
                        <img src="../assets/icon_location.png" alt="">
                        <span>{{ currentEvent.location.name }}</span>
                    </div>
                    <div>
                        <img src="../assets/icon_date.png" alt="">
                        <span>{{ currentEvent.start_date | moment('D MMMM dddd HH:mm') }}</span>
                    </div>
                    <div>
                        <img src="../assets/icon_music.png" alt="">
                        <span>{{ currentEvent.event_type }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    $grid-breakpoints: (
        // Extra small screen / phone
            xs: 0,
        // Small screen / phone
            sm: 576px,
        // Medium screen / tablet
            md: 768px,
        // Large screen / desktop
            lg: 992px,
        // Extra large screen / wide desktop
            xl: 1200px
    );

    .single-event {
        margin: 5px;
        background-color: #464646;
        padding: 15px;

        @media screen and (max-width: map-get($grid-breakpoints, sm)) {
            padding: 5px;
        }

        -webkit-transition: all 200ms ease-in-out;
        -moz-transition: all 200ms ease-in-out;
        -o-transition: all 200ms ease-in-out;
        transition: all 200ms ease-in-out;

        cursor: pointer;

        &:hover {
            background-color: #ffffff;
        }

        &.active {
            background-color: #ffffff;
        }

        img {
            max-width: 100%;
        }
    }

    .single-event-info {
        font-family: 'Oswald', sans-serif;

        margin-left: -10px;
        margin-right: -10px;
        padding: 10px;
        border: 1px solid #656565;
        background-color: rgba(0, 0, 0, 0.65);

        h2 {
            color: #3690ba;
            font-size: 44px;
        }

        p {
            color: #b9b9b9;
            font-size: 22px;
            font-weight: 300;
        }

        a {
            color: #ffffff;
            background-color: #3690ba;
            font-size: 17px;
            border: none;
            padding: 6px 10px;

            text-decoration: none;

            -webkit-transition: all 200ms ease-in-out;
            -moz-transition: all 200ms ease-in-out;
            -o-transition: all 200ms ease-in-out;
            transition: all 200ms ease-in-out;

            &:hover {
                background-color: #285573;
            }
        }

        img {
            margin-top: 13px;
        }
    }

    .single-event-info-icons {
        font-size: 22px;
        font-weight: 300;

        div {
            display: inline-block;
            margin-right: 20px;
            margin-bottom: 10px;

            img {
                margin-right: 8px;
                margin-top: -4px;
            }

            span {
                margin-top: 2px;
            }
        }
    }

    .event-list-arrows {
        position: relative;
    }

    .event-list-arrow {
        position: absolute;
        width: 50px;
        background-color: #146286;
        margin-top: 4px;
        top: 0;
        height: calc(100% - 8px);

        cursor: pointer;

        -webkit-transition: all 200ms ease-in-out;
        -moz-transition: all 200ms ease-in-out;
        -o-transition: all 200ms ease-in-out;
        transition: all 200ms ease-in-out;

        &:hover {
            background-color: #3690ba;
        }

        img {
            position: absolute;
            top: calc(50% - 22.5px);
            left: 10px;
        }
    }

    .event-list-arrow-left {
        left: -70px;
    }

    .event-list-arrow-right {
        right: -70px;
    }

    .event-button-disabled {
        opacity: 0.1;
        cursor: not-allowed;
    }
</style>

<script>
    import axios from 'axios';

    export default {
        name: 'events',
        mounted() {
            this.$appBgImage = this.defaultApiBgImage;

            let that = this;
            axios
                .get(
                    this.apiBaseUrl + 'event/our?format=json',
                    {
                        headers: {
                            'Accept-Language': that.$route.params.language === 'tr' ? 'tr' : 'en'
                        }
                    }
                )
                .then(response => {

                    for (let i=0; i < response.data.length; i++) {
                        if (false === response.data[i].poster.includes('http')) {
                            response.data[i].poster = 'https://back.bluesdernegi.org/' + response.data[i].poster;
                        }
                    }

                    this.items = response.data;

                    this.fourEvents = this.getCurrentFourEvents();
                    this.currentEvent = this.fourEvents[0];
                });
        },
        data: function () {
            return {
                items: null,
                currentEvent: null,
                fourEvents: [],
                eventPointer: 0,
            }
        },
        methods: {
            getCurrentFourEvents: function () {
                return this.items.slice(this.eventPointer, this.eventPointer + 4);
            },
            previousEvent: function () {
                if (this.checkPreviousButton()) {
                    this.eventPointer--;
                }
            },
            nextEvent: function () {
                if (this.checkNextButton()) {
                    this.eventPointer++;
                }
            },
            checkPreviousButton: function () {
                return this.eventPointer > 0;
            },
            checkNextButton: function () {
                return this.eventPointer + 4 < this.items.length;
            },
        },
        watch: {
            eventPointer: function () {
                this.fourEvents = this.getCurrentFourEvents();
            }
        }
    }
</script>