<template>
    <div class="contact-page-main-wrapper">
        <div class="text-center w-100 mt-4" style="padding: 0 15px;" v-if="showForm">

            <div class="row">

                <div class="col-12 col-md-6 mt-4">

                    <div style="height: 120px">

                        <p>
                            {{ $t("contact.address") }}
                        </p>
                        <p>
                            {{ $t("contact.address_2") }}
                        </p>

                        <p>
                            {{ $t("contact.whatsapp") }} <a class="whatsapp-link" href="tel:+905531997481">+90 553 199 74 81</a>
                        </p>
                    </div>

                    <iframe class="w-100" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3011.5285490902725!2d29.026512796963278!3d40.99180320919902!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6f0d594d00f2dc09!2sBlues%20Derne%C4%9Fi%20Merkez%20Ofis!5e0!3m2!1str!2str!4v1575725300811!5m2!1str!2str" width="600" height="354" frameborder="0" style="border:0;" allowfullscreen=""></iframe>

                </div>

                <div class="col-12 col-md-6 contactForm">

                    <div>
                        <p class="mb-0">
                            <label for="name">
                                {{ $t("contact.name") }}
                            </label>
                        </p>
                        <input type="text" id="name" class="w-100" v-model="name">
                        <p class="text-danger" v-if="errors.name">
                            {{ errors.name[0] }}
                        </p>
                    </div>

                    <div>
                        <p class="mb-0 mt-3">
                            <label for="email">
                                {{ $t("contact.email") }}
                            </label>
                        </p>
                        <input type="text" id="email" class="w-100" v-model="email">
                        <p class="text-danger" v-if="errors.email">
                            {{ errors.email[0] }}
                        </p>
                    </div>

                    <div>
                        <p class="mb-0 mt-3">
                            <label for="message">
                                {{ $t("contact.message") }}
                            </label>
                        </p>
                        <textarea name="message" id="message" cols="30" rows="3" class="w-100" v-model="message"></textarea>
                        <p class="text-danger" v-if="errors.message">
                            {{ errors.message[0] }}
                        </p>
                        <span>
                        {{ $t("contact.info") }}
                    </span>
                    </div>

                    <div class="mt-4">
                        <button type="button" @click="send()" v-if="false === isLoading">
                            {{ $t("contact.submit") }}
                        </button>
                        <div class="spinner-border" role="status" v-if="isLoading">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>

                </div>

            </div>

            <div class="mt-5 contact-social-links">
                <a href="https://www.facebook.com/bluesdernegi" target="_blank">
                    <img src="../assets/contact/facebook_logo.png" alt="">
                </a>
                <a href="https://www.instagram.com/bluesdernegi/" target="_blank">
                    <img src="../assets/contact/instagram_logo.png" alt="">
                </a>
                <a href="https://twitter.com/BluesDernegi" target="_blank">
                    <img src="../assets/contact/twitter_logo.png" alt="">
                </a>
                <a href="tel:+905531997481">
                    <img src="../assets/contact/whatsapp_logo.png" alt="">
                </a>
                <a href="https://www.youtube.com/channel/UC9m0uzRcMhjkHRSYSzjAFQQ" target="_blank">
                    <img src="../assets/contact/youtube_logo.png" alt="">
                </a>
                <a href="https://open.spotify.com/user/c4swah1j5cewhwsnxd8l33nr1?si=NVoZZBwYRTO1LK_Qh7K-2g" target="_blank">
                    <img src="../assets/contact/spotify_logo.png" alt="">
                </a>
            </div>

        </div>


        <div class="text-center w-100" style="padding: 0 15px;" v-if="false === showForm">

            <h1>{{ $t("contact.thanks") }}</h1>

        </div>

    </div>
</template>

<style lang="scss" scoped>
    $grid-breakpoints: (
        // Extra small screen / phone
            xs: 0,
        // Small screen / phone
            sm: 576px,
        // Medium screen / tablet
            md: 768px,
        // Large screen / desktop
            lg: 992px,
        // Extra large screen / wide desktop
            xl: 1200px
    );

    .contact-page-main-wrapper {
        height: calc(100% - 105px);
        font-family: 'Oswald', sans-serif;

        & > div {
            height: 100%;
            max-width: 1000px;
            margin: auto;
        }

        label {
            color: #d4d4d4;
            font-size: 20px;
            clear: both;
        }

        input, textarea {
            border: 3px solid #000000;
            -webkit-box-shadow: inset 0 5px 10px 0 rgba(0, 0, 0, 0.4);
            -moz-box-shadow: inset 0 5px 10px 0 rgba(0, 0, 0, 0.4);
            box-shadow: inset 0 5px 10px 0 rgba(0, 0, 0, 0.4);
            padding: 4px 6px;
        }

        span {
            color: #bcbcbc;
            font-size: 15px;
            font-weight: 200;
            font-style: italic;
        }

        button {
            font-size: 18px;
            color: #d4d4d4;
            background-color: #003154;
            border: 1px solid #002241;
            padding: 4px 50px;

            -webkit-transition: all 200ms ease-in-out;
            -moz-transition: all 200ms ease-in-out;
            -o-transition: all 200ms ease-in-out;
            transition: all 200ms ease-in-out;

            &:hover {
                background-color: #005685;
            }
        }

        .whatsapp-link {
            color: #128C7E;
            text-decoration: none;

            &:hover {
                color: #075E54;
            }
        }

        .contactForm {
            margin-top: 103px;

            @media screen and (max-width: map-get($grid-breakpoints, sm)) {
                margin-top: 20px;
            }
        }
    }

    .contact-social-links {
        a {
            margin-right: 4px;

            img {
                -webkit-transition: all 200ms ease-in-out;
                -moz-transition: all 200ms ease-in-out;
                -o-transition: all 200ms ease-in-out;
                transition: all 200ms ease-in-out;

                opacity: 0.6;

                &:hover {
                    opacity: 1;
                    transform: scale(1.05);
                }
            }
        }
    }
</style>

<script>
    import axios from 'axios';

    export default {
        name: "contact",
        mounted() {
            this.$appBgImage = this.defaultApiBgImage;
        },
        data: () => {
            return {
                name: '',
                email: '',
                message: '',

                isLoading: false,
                showForm: true,
                errors: {
                    name: null,
                    email: null,
                    message: null,
                }
            }
        },
        methods: {
            send() {
                this.isLoading = true;

                // reset the errors
                this.errors = {
                    name: null,
                    email: null,
                    message: null,
                };

                axios
                    .post(this.apiBaseUrl + 'send/', {
                        'name': this.name,
                        'email': this.email,
                        'message': this.message
                    })
                    .then(response => {
                        this.isLoading = false;
                        if (201 === response.status) {
                            this.showForm = false;
                        }
                    }).catch((error) => {
                        this.isLoading = false;
                        this.errors = error.response.data;
                    }
                );
            }
        }
    }
</script>