<template>
    <div id="app">

        <router-link :to="{ name: 'home', params: { language: $i18n.locale } }" :class="{ 'opacity-0': $route.name === 'home' }" class="logo">
            <img v-if="'tr' === $route.params.language" src="./assets/logo.png" alt="">
            <img v-else src="./assets/logo_en.png" alt="">
        </router-link>

        <a href="javascript:void(0)" class="d-block d-lg-none app-menu" @click="isMobileMenuOpened = true">
            <div></div>
            <div></div>
            <div></div>
        </a>

        <div v-if="isMobileMenuOpened" class="app-menu-content text-center">

            <router-link :to="{ name: 'home', params: { language: $i18n.locale } }">
                <img v-if="'tr' === $route.params.language" src="./assets/logo.png" alt="">
                <img v-else src="./assets/logo_en.png" alt="">
            </router-link>

            <div class="app-menu-close-icon" @click="isMobileMenuOpened = false">
                <div></div>
                <div></div>
            </div>

            <router-link class="link" :to="{ name: 'home', params: { language: $i18n.locale } }">
                {{ $t("app.homepage") }}
            </router-link>
            <router-link class="link" :to="{ name: 'about_mission_and_vision' + ('en' === $route.params.language ? '_en' : ''), params: { language: $i18n.locale } }" :class="{ 'router-link-exact-active': isAboutPage }">
                {{ $t("app.about") }}
            </router-link>
            <router-link class="link" :to="{ name: 'events' + ('en' === $route.params.language ? '_en' : ''), params: { language: $i18n.locale } }">
                {{ $t("app.events") }}
            </router-link>
            <a class="link" :href="'en' === $route.params.language ? 'https://blog.bluesdernegi.org/en/' : 'https://blog.bluesdernegi.org/'" target="_blank">
                {{ $t("app.blog") }}
            </a>
<!--            <a class="link" href="https://forum.bluesdernegi.org/" target="_blank">-->
<!--                {{ $t("app.forum") }}-->
<!--            </a>-->
            <router-link class="link" :to="{ name: 'musicians' + ('en' === $route.params.language ? '_en' : ''), params: { language: $i18n.locale } }">
                {{ $t("app.musicians") }}
            </router-link>
            <router-link class="link" :to="{ name: 'gallery' + ('en' === $route.params.language ? '_en' : ''), params: { language: $i18n.locale } }">
                {{ $t("app.gallery") }}
            </router-link>
            <router-link class="link" :to="{ name: 'playlists' + ('en' === $route.params.language ? '_en' : ''), params: { language: $i18n.locale } }">
                {{ $t("app.playlists") }}
            </router-link>
<!--            <router-link class="link" :to="{ name: 'blues-calendar' + ('en' === $route.params.language ? '_en' : ''), params: { language: $i18n.locale } }">-->
<!--                {{ $t("app.blues_calendar") }}-->
<!--            </router-link>-->
            <router-link class="link" :to="{ name: 'membership' + ('en' === $route.params.language ? '_en' : ''), params: { language: $i18n.locale } }">
                {{ $t("app.membership") }}
            </router-link>
            <router-link class="link" :to="{ name: 'contact' + ('en' === $route.params.language ? '_en' : ''), params: { language: $i18n.locale } }">
                {{ $t("app.contact") }}
            </router-link>

            <router-link class="link" :to="{ name: 'home', params: { language: ('en' === $route.params.language ? 'tr' : 'en') } }">
                <font-awesome-icon :icon="['fas', 'globe']" />
                {{ 'en' === $route.params.language ? 'Turkish' : 'English' }}
            </router-link>
        </div>

        <div class="nav d-flex justify-content-center">
            <router-link class="d-none d-lg-block" :to="{ name: 'home', params: { language: $i18n.locale } }" :class="{ 'router-link-exact-active': 'home' === $route.name }">
                {{ $t("app.homepage") }}
            </router-link>
            <router-link class="d-none d-lg-block" :to="{ name: 'about_mission_and_vision' + ('en' === $route.params.language ? '_en' : ''), params: { language: $i18n.locale } }" :class="{ 'router-link-exact-active': isAboutPage }">
                {{ $t("app.about") }}
            </router-link>
            <a class="d-none d-lg-block" :href="'en' === $route.params.language ? 'https://blog.bluesdernegi.org/en/' : 'https://blog.bluesdernegi.org/'" target="_blank">
                {{ $t("app.blog") }}
            </a>
<!--            <a class="d-none d-lg-block" href="https://forum.bluesdernegi.org/" target="_blank">-->
<!--                {{ $t("app.forum") }}-->
<!--            </a>-->
            <router-link class="d-none d-lg-block" :to="{ name: 'events' + ('en' === $route.params.language ? '_en' : ''), params: { language: $i18n.locale } }">
                {{ $t("app.events") }}
            </router-link>
            <router-link class="d-none d-lg-block" :to="{ name: 'musicians' + ('en' === $route.params.language ? '_en' : ''), params: { language: $i18n.locale } }">
                {{ $t("app.musicians") }}
            </router-link>
            <router-link class="d-none d-lg-block" :to="{ name: 'gallery' + ('en' === $route.params.language ? '_en' : ''), params: { language: $i18n.locale } }">
                {{ $t("app.gallery") }}
            </router-link>
            <router-link class="d-none d-lg-block" :to="{ name: 'playlists' + ('en' === $route.params.language ? '_en' : ''), params: { language: $i18n.locale } }">
                {{ $t("app.playlists") }}
            </router-link>
<!--            <router-link class="d-none d-lg-block" :to="{ name: 'blues-calendar' + ('en' === $route.params.language ? '_en' : ''), params: { language: $i18n.locale } }">-->
<!--                {{ $t("app.blues_calendar") }}-->
<!--            </router-link>-->
            <router-link class="d-none d-lg-block" :to="{ name: 'membership' + ('en' === $route.params.language ? '_en' : ''), params: { language: $i18n.locale } }">
                {{ $t("app.membership") }}
            </router-link>
            <router-link class="d-none d-lg-block" :to="{ name: 'contact' + ('en' === $route.params.language ? '_en' : ''), params: { language: $i18n.locale } }">
                {{ $t("app.contact") }}
            </router-link>

            <router-link class="d-none d-lg-block" :to="{ name: 'home', params: { language: ('en' === $route.params.language ? 'tr' : 'en') } }">
                <font-awesome-icon :icon="['fas', 'globe']" />
                {{ 'en' === $route.params.language ? 'Turkish' : 'English' }}
            </router-link>
        </div>

        <!--        <div id="app-page-content" style="height: 100%;">-->
        <!--            <router-view/>-->
        <!--        </div>-->

        <router-view/>

        <div :class="{ 'opacity-0': $route.name === 'contact' || $route.name === 'contact_en' }"
             class="fixed-social-media-icons d-none d-lg-block"
        >
            <a href="https://open.spotify.com/user/c4swah1j5cewhwsnxd8l33nr1?si=NVoZZBwYRTO1LK_Qh7K-2g" target="_blank">
                <img src="./assets/social-media-icons/spotify_logo.png" alt="">
            </a>
            <a href="https://www.youtube.com/channel/UC9m0uzRcMhjkHRSYSzjAFQQ" target="_blank">
                <img src="./assets/social-media-icons/youtube_logo.png" alt="">
            </a>
            <a href="tel:+905531997481">
                <img src="./assets/social-media-icons/whatsapp_logo.png" alt="">
            </a>
            <a href="https://twitter.com/BluesDernegi" target="_blank">
                <img src="./assets/social-media-icons/twitter_logo.png" alt="">
            </a>
            <a href="https://www.instagram.com/bluesdernegi/" target="_blank">
                <img src="./assets/social-media-icons/instagram_logo.png" alt="">
            </a>
            <a href="https://www.facebook.com/bluesdernegi" target="_blank">
                <img src="./assets/social-media-icons/facebook_logo.png" alt="">
            </a>
            <a href="mailto:info@bluesdernegi.org" target="_blank">
                <img src="./assets/social-media-icons/mail_icon.png" alt="">
            </a>
        </div>

    </div>
</template>

<style lang="scss">
    html {
        height: 100%;
    }

    body {
        height: 100%;
        color: #ffffff !important;
        font-family: 'Open Sans', sans-serif !important;

        /*background-image: url("/bg/background.jpg");*/
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;

        -webkit-transition: background-image 500ms ease-in-out;
        -moz-transition: background-image 500ms ease-in-out;
        -o-transition: background-image 500ms ease-in-out;
        transition: background-image 500ms ease-in-out;
    }

    #app {
        height: 100%;
    }

    .logo {
        position: absolute;
        left: 40px;
        top: 6px;

        -webkit-transition: all 500ms ease-in-out;
        -moz-transition: all 500ms ease-in-out;
        -o-transition: all 500ms ease-in-out;
        transition: all 500ms ease-in-out;

        img {
            height: 58px;
        }
    }

    .app-menu {
        position: absolute;
        right: 24px;
        top: 24px;
        color: #ffffff;

        div {
            display: block;
            width: 40px;
            height: 4px;
            margin-bottom: 6px;
            background: #ffffff;
        }
    }

    .app-menu-content {
        position: fixed;
        height: 100%;
        width: 100%;
        z-index: 999;
        overflow-x: hidden;
        left: 0;
        right: 0;

        background-color: #1d1d1d;

        img {
            margin: 12px;
            height: 58px;
        }

        .app-menu-close-icon {
            position: absolute;
            right: 24px;
            top: 24px;

            width: 30px;
            height: 30px;

            div {
                position: absolute;
                left: 0;
                top: 13px;

                display: block;
                height: 3px;
                width: 100%;
                background-color: #ffffff;
                border-radius: 3px;

                transform: rotate(45deg);

                &:last-child {
                    transform: rotate(135deg);
                }
            }
        }

        .link {
            display: block;

            color: #ffffff;
            text-decoration: none !important;
            font-size: 20px;

            line-height: 60px;

            border-bottom: 1px dotted rgba(255, 255, 255, 0.1);

            &:last-child {
                border-bottom: none;
            }

            &.router-link-exact-active {
                color: #ffc103;
            }

            &:active {
                color: #ffc103;
            }
        }
    }

    .nav {
        background: rgba(0, 0, 0, 0.7);
        border-bottom: 1px solid #1d1d1d;
        min-height: 70px;

        a {
            color: #ffffff;
            text-decoration: none !important;
            font-size: 16px;

            margin: 24px 15px 0;
            padding-bottom: 20px;
            font-weight: bold;
            opacity: 0.6;

            -webkit-transition: all 200ms ease-in-out;
            -moz-transition: all 200ms ease-in-out;
            -o-transition: all 200ms ease-in-out;
            transition: all 200ms ease-in-out;

            border-bottom: 2px solid transparent;

            &:hover {
                color: #ffffff;
                opacity: 1;
            }

            &.router-link-exact-active {
                opacity: 1;
                border-bottom: 2px solid #fff;
            }
        }
    }

    .fixed-social-media-icons {
        position: absolute;
        top: 50%;
        margin-top: -185px;
        right: 20px;

        -webkit-transition: all 200ms ease-in-out;
        -moz-transition: all 200ms ease-in-out;
        -o-transition: all 200ms ease-in-out;
        transition: all 200ms ease-in-out;

        a {
            display: block;
            margin-bottom: 10px;
            opacity: 0.5;

            &:hover {
                opacity: 1;
                -webkit-transform: scale(1.1);
                -moz-transform: scale(1.1);
                -ms-transform: scale(1.1);
                -o-transform: scale(1.1);
                transform: scale(1.1);
            }
        }
    }

    .opacity-0 {
        opacity: 0;
    }
</style>

<script>
    export default {
        name: 'app',
        data: () => {
            return {
                isMobileMenuOpened: false,
                isAboutPage: false,
            }
        },
        watch: {
            $route(value) {
                this.isMobileMenuOpened = false;

                this.isAboutPage = value.name === 'about_mission_and_vision'
                    || value.name === 'about_charter'
                    || value.name === 'about_board_of_directors'
                    || value.name === 'about_mission_and_vision_en'
                    || value.name === 'about_charter_en'
                    || value.name === 'about_board_of_directors_en';
            }
        },
    }
</script>
