<template>
    <div class="page-main-wrapper container mt-4">

        <div class="row">

            <div class="col-12 col-lg-3" v-for="item in spotifyItems" v-bind:key="item.id">

                <div class="iframe-loading">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>

                <p>{{ 'en' === $route.params.language ? item.name_en : item.name }}</p>

                <iframe :src="item.iframeUrl"
                        width="100%" height="80" frameborder="0" allowtransparency="true"
                        allow="encrypted-media"></iframe>

            </div>
            
        </div>

        <div class="row mt-5">

            <div class="col-12 col-lg-3 mb-5" v-for="item in youtubeItems" v-bind:key="item.id">

                <div class="iframe-loading">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>

                <p>{{ item.name }}</p>

                <iframe width="100%" height="100%" :src="item.iframeUrl" frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>

            </div>

        </div>

    </div>
</template>

<style lang="scss" scoped>
    .page-main-wrapper {
        font-family: 'Open Sans', sans-serif;

        p {
            font-weight: 600;
            font-size: 17px;
        }

        iframe {
            position:relative;
            z-index: 2;
        }
    }
    .iframe-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -0.75rem;
        z-index: 1;
    }
</style>

<script>
    export default {
        name: "playlists",
        mounted: function () {
            this.$appBgImage = '/bg/background_web_spotify.jpg';
        },
        data: function () {
            return {
                spotifyItems: [
                    {
                        id: 1,
                        name: 'Jump Blues Klasikleri',
                        name_en: 'Jump Blues Classics',
                        iframeUrl: 'https://open.spotify.com/embed/playlist/1uk5j069dILOTbPMN8G4M5'
                    },
                    {
                        id: 2,
                        name: 'Deniz Tuzcuoğlu Seçkileri',
                        name_en: "Deniz Tuzcuoğlu's Selections",
                        iframeUrl: 'https://open.spotify.com/embed/playlist/2R6IhSX0nC7DU36aNuMHN4'
                    },
                    {
                        id: 3,
                        name: 'Guray Oskay Seçkileri',
                        name_en: "Guray Oskay's Selections",
                        iframeUrl: 'https://open.spotify.com/embed/playlist/4qxqBKEG2xCdnp3D5SSBlu'
                    },
                    {
                        id: 4,
                        name: 'Alper Durmuş Seçkileri',
                        name_en: "Alper Durmuş's Selections",
                        iframeUrl: 'https://open.spotify.com/embed/playlist/6g3d2d2StKzn3jH8ONvmHk'
                    },
                    {
                        id: 5,
                        name: 'Soner Doğanca Sizler için Seçti',
                        name_en: "Soner Doğanca's Selections",
                        iframeUrl: 'https://open.spotify.com/embed/playlist/1ie203pgkyYx22Bnhx72YN'
                    },
                    {
                        id: 6,
                        name: 'Gürkan Özbek Sizler için Seçti',
                        name_en: "Gürkan Özbek's Selections",
                        iframeUrl: 'https://open.spotify.com/embed/playlist/3ZMciaDa1roLAmbSfblTMy'
                    },
                    {
                        id: 7,
                        name: 'Jeff Shucard Sizler için Seçti',
                        name_en: "Jeff Shucard's Selections",
                        iframeUrl: 'https://open.spotify.com/embed/playlist/12sV07bxjKo4BQdAvHnYL8'
                    },
                    {
                        id: 8,
                        name: 'Batu Mutlugil Sizler için Seçti',
                        name_en: "Batu Mutlugil's Selections",
                        iframeUrl: 'https://open.spotify.com/embed/playlist/5CCl0MDc55PMhIBmF7mqnS'
                    },
                    {
                        id: 9,
                        name: 'Göksenin Sizler için Seçti',
                        name_en: "Göksenin's Selections",
                        iframeUrl: 'https://open.spotify.com/embed/playlist/2L7hhaiQkC7cZcdpgNL2JW'
                    },
                    {
                        id: 10,
                        name: 'Dinçer Tuğmaner Seçkileri',
                        name_en: "Dinçer Tuğmaner's Selections",
                        iframeUrl: 'https://open.spotify.com/embed/playlist/12sV07bxjKo4BQdAvHnYL8'
                    },
                    {
                        id: 11,
                        name: 'Blaine Dunaway Seçkileri',
                        name_en: "Blaine Dunaway's Selections",
                        iframeUrl: 'https://open.spotify.com/embed/playlist/733nOk66sVTKpvQ5jwO5uv'
                    },
                    {
                        id: 12,
                        name: 'Ertan Kızıltan Sizler için Seçti',
                        name_en: "Ertan Kızıltan's Selections",
                        iframeUrl: 'https://open.spotify.com/embed/playlist/0ZGXs8T0s4Y8aepK3fZcVG'
                    },
                    
                ],
                youtubeItems: [
                    {
                        id: 1,
                        name: 'Blues\'da Üflemeliler Atölyesi',
                        iframeUrl: 'https://www.youtube.com/embed/1WQLNiJ9h6k'
                    },
                    {
                        id: 2,
                        name: 'Mızıka Atolyesi',
                        iframeUrl: 'https://www.youtube.com/embed/9GIWg5l2Dmw'
                    },
                    {
                        id: 3,
                        name: 'Janis Joplin Anısı',
                        iframeUrl: 'https://www.youtube.com/embed/3Htk1wuHJhQ'
                    },
                    {
                        id: 4,
                        name: 'Blues Derneği Açılışı',
                        iframeUrl: 'https://www.youtube.com/embed/sejB4oNMeE0'
                    },
                ]
            }
        }
    }
</script>