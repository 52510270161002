import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import VueI18n from 'vue-i18n'
import VueGtag from "vue-gtag"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faGlobe);
Vue.component('font-awesome-icon', FontAwesomeIcon)

window.changeBodyBgImage = (url) => {
    document.querySelector('body').style.backgroundImage = 'url(' + url + ')';
};

Vue.config.productionTip = false;

let globalData = new Vue({
    data: {$appBgImage: '/bg/background.jpg'}
});

Vue.mixin({
    data: function () {
        return {
            get apiBaseUrl() {
                return 'https://back.bluesdernegi.org/api/';
            },
            get defaultApiBgImage() {
                return '/bg/background.jpg';
            }
        }
    },
    computed: {
        $appBgImage: {
            get: function () {
                return globalData.$data.$appBgImage
            },
            set: function (newBgImage) {
                window.changeBodyBgImage(newBgImage);
                globalData.$data.$appBgImage = newBgImage;
            }
        }
    }
});

const moment = require('moment');
require('moment/locale/tr');

Vue.use(require('vue-moment'), {
    moment
});

Vue.use(VueGtag, {
    config: {
        id: "G-5383PT12QZ",
        params: {
            send_page_view: false
        }
    }
}, router);

router.beforeEach((to, from, next) => {
    // use the language from the routing param or default language
    let language = to.params.language;

    // Example: "/abc/gallery"
    if (language && 'tr' !== language && 'en' !== language) {
        next('/');
        return;
    }

    // Browser language
    if (!language) {
        language = navigator.language.split('-')[0];
    }

    if ('tr' !== language && 'en' !== language) {
        language = 'tr';
    }

    // set the current language for vuex-i18n. note that translation data
    // for the language might need to be loaded first
    i18n.locale = language;

    const translationKey = 'routes.' + to.name.replace('_en', '') + '.title';
    const translatedTitle = i18n.t(translationKey);
    if (translatedTitle !== translationKey) {
        document.title = String(translatedTitle);
    } else {
        document.title = 'Blues Derneği';
    }

    next();
});

// ---------------------------------------------------------------------------------------------------------------------

const messages = {
    tr: require('./translations/tr'),
    en: require('./translations/en'),
};

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'tr',
    fallbackLocale: 'tr',
    messages
});

// ---------------------------------------------------------------------------------------------------------------------

new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app');

window.addEventListener('load', () => {
    const bgImages = [
        '/bg/background.jpg',
        '/bg/background_web_calendar.jpg',
        '/bg/background_web_contact.jpg',
        '/bg/background_web_event.jpg',
        '/bg/background_web_membership.jpg',
        '/bg/background_web_musicians.jpg',
        '/bg/background_web_spotify.jpg',
        '/bg/background_web_woman.jpg',
        '/bg/background_web_woman06.jpg',
    ];

    for (let i=0; i < bgImages.length; i++) {
        let bgImage = new Image();
        bgImage.src = bgImages[i];
    }
});