<template>
    <div class="home">

        <div class="welcome-card d-flex align-items-center">
            <div class="w-100">
                <p class="text-center">
                    <img v-if="'tr' === $route.params.language" src="../assets/logo.png" alt="">
                    <img v-else src="../assets/logo_en.png" alt="">
                </p>
                <p class="text-center">
                    {{ $t("homepage.message_1") }}<br v-if="$t('homepage.message_2').length > 0">
                    {{ $t("homepage.message_2") }}<br v-if="$t('homepage.message_3').length > 0">
                    {{ $t("homepage.message_3") }}<br v-if="$t('homepage.message_4').length > 0">
                    {{ $t("homepage.message_4") }}
                </p>

            </div>
        </div>

    </div>
</template>

<style lang="scss" scoped>
    $grid-breakpoints: (
        // Extra small screen / phone
            xs: 0,
        // Small screen / phone
            sm: 576px,
        // Medium screen / tablet
            md: 768px,
        // Large screen / desktop
            lg: 992px,
        // Extra large screen / wide desktop
            xl: 1200px
    );

    .home {
        height: calc(100% - 105px);
    }

    .welcome-card {
        height: 100%;
        max-width: 500px;
        margin: auto;
        color: #a3a3a3;

        p {
            font-size: 26px;
            word-spacing: 10px;

            @media screen and (max-width: map-get($grid-breakpoints, sm)) {
                font-size: 18px;
            }
        }

        img {
            width: 250px;

            @media screen and (max-width: map-get($grid-breakpoints, sm)) {
                width: 190px;
            }
        }
    }
</style>

<script>
    // todo title'lar değişmeli
    // todo tab'ler için route

    export default {
        name: 'home',
        mounted() {
            this.$appBgImage = this.defaultApiBgImage;
        }
    }
</script>
