import Vue from 'vue'
import Router from 'vue-router'

import Home from './views/Home';
import About from './views/About';
import Events from './views/Events';
import Musicians from './views/Musicians';
import Gallery from './views/Gallery';
import Playlists from './views/Playlists';
// import BluesCalendar from './views/BluesCalendar';
import Membership from './views/Membership';
import Contact from './views/Contact';
import NotFound from './views/NotFound';

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/:language?/hakkinda/misyon-ve-vizyon',
            name: 'about_mission_and_vision',
            component: About,
            meta: {tab: 1}
        },
        {
            path: '/:language?/about/mission-and-vision',
            name: 'about_mission_and_vision_en',
            component: About,
            meta: {tab: 1}
        },
        {
            path: '/:language?/hakkinda/tuzuk',
            name: 'about_charter',
            component: About,
            meta: {tab: 3}
        },
        {
            path: '/:language?/about/charter',
            name: 'about_charter_en',
            component: About,
            meta: {tab: 3}
        },
        {
            path: '/:language?/hakkinda/yonetim-kurulu',
            name: 'about_board_of_directors',
            component: About,
            meta: {tab: 4}
        },
        {
            path: '/:language?/about/board-of-directors',
            name: 'about_board_of_directors_en',
            component: About,
            meta: {tab: 4}
        },
        {
            path: '/:language?/hakkinda/faaliyet-raporlari',
            name: 'about_annual_reports',
            component: About,
            meta: {tab: 5}
        },
        {
            path: '/:language?/about/annual-reports',
            name: 'about_annual_reports_en',
            component: About,
            meta: {tab: 5}
        },
        {
            path: '/:language?/etkinlikler',
            name: 'events',
            component: Events,
        },
        {
            path: '/:language?/events',
            name: 'events_en',
            component: Events,
        },
        {
            path: '/:language?/uye-muzisyenler',
            name: 'musicians',
            component: Musicians,
        },
        {
            path: '/:language?/musicians',
            name: 'musicians_en',
            component: Musicians,
        },
        {
            path: '/:language?/galeri',
            name: 'gallery',
            component: Gallery
        },
        {
            path: '/:language?/gallery',
            name: 'gallery_en',
            component: Gallery
        },
        {
            path: '/:language?/playlistler',
            name: 'playlists',
            component: Playlists,
        },
        {
            path: '/:language?/playlists',
            name: 'playlists_en',
            component: Playlists,
        },
        // {
        //     path: '/:language?/blues-takvimi',
        //     name: 'blues-calendar',
        //     component: BluesCalendar,
        // },
        // {
        //     path: '/:language?/blues-calendar',
        //     name: 'blues-calendar_en',
        //     component: BluesCalendar,
        // },
        {
            path: '/:language?/uyelik',
            name: 'membership',
            component: Membership,
        },
        {
            path: '/:language?/membership',
            name: 'membership_en',
            component: Membership,
        },
        {
            path: '/:language?/iletisim',
            name: 'contact',
            component: Contact,
        },
        {
            path: '/:language?/contact',
            name: 'contact_en',
            component: Contact,
        },

        // ----------------------------------------

        {
            path: '/:language?//',
            name: 'home',
            component: Home,
        },

        // ----------------------------------------

        {
            path: '*',
            name: 'not_found',
            component: NotFound,
        }
    ]
})
