<template>
    <div class="page-main-wrapper">
        <div class="mt-5">

            <div class="text-center w-100" v-if="counterForLoading !== 2">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>

            <div class="row" style="margin: 0;">

                <div class="col-12 col-lg-2 musicians mt-3" v-if="counterForLoading === 2">

                    <h2 class="title-musicians mb-2 text-center" @click="showMusicians = !showMusicians">
                        {{ $t("musicians.member_musicians") }}
                        <span>
                            &nbsp;{{ showMusicians ? '^' : '∨' }}
                        </span>
                    </h2>

                    <div class="text-center" v-if="showMusicians">
                        <div v-for="item in musicians" v-bind:key="item.id">
                            <a href="javascript:void(0)" @click="currentItem = item; changeItem();"
                               :class="{ 'active2': currentItem === item }">
                                {{ item.name }} {{ item.surname }}
                            </a>
                        </div>
                    </div>

                    <h3 class="title-musicians mt-3 text-center" @click="showMusicGroups = !showMusicGroups">
                        {{ $t("musicians.member_groups") }}
                        <span>
                            &nbsp;{{ showMusicGroups ? '^' : '∨' }}
                        </span>
                    </h3>

                    <div class="text-center" v-if="showMusicGroups">
                        <div v-for="item in musicGroups" v-bind:key="item.id">
                            <a href="javascript:void(0)" @click="currentItem = item; changeItem();"
                               :class="{ 'active2': currentItem === item }">
                                {{ item.name }}
                            </a>
                        </div>
                    </div>

                </div>

                <div class="col-12 col-lg-8" v-if="currentItem">

                    <div class="musician mt-2" v-if="currentItem" :id="(currentItem.surname ? 'm' : 'g') + currentItem.id">

                        <div class="row">

                            <div class="col-12 col-lg-3">

                                <h2 class="d-block d-lg-none text-center">{{ currentItem.name }} {{ currentItem.surname }}</h2>

                                <img v-if="currentItem.surname"
                                     :src="
                                        (
                                        typeof currentItem.profile_picture === 'undefined' ||
                                        currentItem.profile_picture === null
                                        )
                                        ?
                                        '/demo/musician_default.png'
                                        :
                                        currentItem.profile_picture"
                                     alt=""
                                     class="musician-profile-picture"
                                >

                                <img v-if="!currentItem.surname"
                                     :src="
                                        (
                                        typeof currentItem.pic === 'undefined' ||
                                        currentItem.pic === null
                                        )
                                        ?
                                        '/demo/musicgroup_default.png'
                                        :
                                         currentItem.pic"
                                     alt=""
                                     class="musician-profile-picture"
                                >

                                <div class="musician-description mb-3">

                                    <p class="mb-0" v-if="currentItem.surname">
                                        {{ $t("musician.date_of_birth") }} <span>{{ (new Date(currentItem.birthdate)).getFullYear() }}</span>
                                    </p>

                                    <p class="mb-0" v-if="currentItem.start_date_to_music">
                                        {{ $t("musician.active_years") }} <span>{{ (new Date(currentItem.start_date_to_music)).getFullYear() }} -
                                        {{ currentItem.is_continue ?
                                        $t("musician.present") :
                                        (new Date(currentItem.finish_date_to_music)).getFullYear() }}</span>
                                    </p>
                                    <p v-else class="mb-0">
                                        {{ $t("musician.active_years") }} <span>{{ (new Date(currentItem.birthdate)).getFullYear() }} -
                                        {{ currentItem.is_continue ?
                                        $t("musician.present") :
                                        (new Date(currentItem.finish_date_to_music)).getFullYear() }}</span>
                                    </p>

                                    <p class="mb-0" v-if="currentItem.instruments">
                                        {{ $t("musician.instruments") }} <span>{{ currentItem.instruments.join(', ') }}</span>
                                    </p>

                                    <p class="mb-0" v-if="currentItem.genre">
                                        {{ $t("musician.genre") }} <span>{{ currentItem.genre.join(', ') }}</span>
                                    </p>

                                </div>

                            </div>

                            <div class="col-12 col-lg-9">

                                <h2 class="d-none d-lg-block">{{ currentItem.name }} {{ currentItem.surname }}</h2>

                                <p class="mb-0 mt-3">{{ $t("musician.compositions_and_lyrics") }}</p>
                                <p class="mb-3">{{ $t("generic.very_soon") }}</p>

                                <p class="mb-0" v-if="currentItem.workedwith && currentItem.workedwith.length > 0">
                                <span v-for="person in currentItem.workedwith" :key="person.id">
                                    <div v-html="person.note"></div>
                                </span>
                                </p>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>
    </div>
</template>

<style lang="scss" scoped>
    .page-main-wrapper {
        height: calc(100% - 105px);
        font-family: 'Open Sans', sans-serif;
    }

    a {
        text-decoration: none;
        color: #ffffff;

        &:hover {
            color: #ffc103;
        }

        &.active {
            color: #ffc103;
            border-bottom: 1px solid #ffc103;
        }
    }

    .musicians {
        padding-left: 40px;
        margin-bottom: 20px;
        font-weight: bold;
    }

    .musician {
        position: relative;
        padding: 10px;

        h2 {
            color: #ffc103;
            font-weight: bold;
        }

        p {
            color: #e0e0e0;
        }
    }

    .active2 {
        color: #ffc103;
    }

    .characters {
        a {
            padding: 0 7px;
            margin-right: 10px;
            font-size: 18px;

            &.active {
                color: #000;
                background-color: #9b9b9b;
                border-bottom: none;
                -webkit-border-radius: 1px;
                -moz-border-radius: 1px;
                border-radius: 1px;
            }
        }
    }

    .title-musicians {
        color: #ffc103;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;

        span {
            font-size: 16px;
        }
    }

    .musician-profile-picture {
        width: 100%;
        margin-bottom: 20px;

        border: 1px solid #1f1b1b;
        background: #000000;
        padding: 10px;

        -webkit-box-shadow: 0px 5px 5px 0px rgba(50, 50, 50, 0.75);
        -moz-box-shadow: 0px 5px 5px 0px rgba(50, 50, 50, 0.75);
        box-shadow: 0px 5px 5px 0px rgba(50, 50, 50, 0.75);
    }

    .musician-description {
        padding: 10px;
        background: rgba(42, 42, 42, 0.65);
        border: 1px solid #636363;
        font-size: 13px;

        span {
            color: #ffc103;
        }
    }
</style>

<script>
    import axios from 'axios';

    export default {
        name: "musicians",
        data() {
            return {
                counterForLoading: 0,

                musicians: null,
                musicGroups: null,

                currentItem: null,

                showMusicians: false,
                showMusicGroups: false,
            }
        },
        mounted() {
            this.$appBgImage = '/bg/background_web_musicians.jpg';

            let randomSelectorForMusician = Math.random() >= 0.5;

            axios
                .get(this.apiBaseUrl + 'musician/?format=json')
                .then(response => {
                    this.musicians = response.data;

                    if (randomSelectorForMusician) {
                        this.currentItem = this.musicians[Math.floor(Math.random() * this.musicians.length)];
                    }

                    this.counterForLoading++;
                });

            axios
                .get(this.apiBaseUrl + 'musicgroup/?format=json')
                .then(response => {
                    this.musicGroups = response.data;

                    if (false === randomSelectorForMusician) {
                        this.currentItem = this.musicGroups[Math.floor(Math.random() * this.musicGroups.length)];
                    }

                    this.counterForLoading++;
                })
        },
        methods: {
            changeItem: function () {

                if (window.outerWidth < 992) {
                    let thatThis = this;

                    setTimeout(function () {
                        window.location.hash = (thatThis.currentItem.surname ? 'm' : 'g') + thatThis.currentItem.id;
                    }, 100);
                } else {
                    window.scrollTo(0, 0);
                }

            }
        },
        watch: {
            currentItem: function () {

                if (
                    typeof this.currentItem.surname !== 'undefined' &&
                    typeof this.currentItem.workedwith === 'undefined'
                ) {
                    axios
                        .get(this.apiBaseUrl + 'musician/workedwith/' + this.currentItem.id + '?format=json')
                        .then(response2 => {
                            this.currentItem.workedwith = response2.data;
                            this.$forceUpdate();
                        });
                }

                if (
                    typeof this.currentItem.surname === 'undefined' &&
                    typeof this.currentItem.workedwith === 'undefined'
                ) {
                    axios
                        .get(this.apiBaseUrl + 'group/works/' + this.currentItem.id + '?format=json')
                        .then(response2 => {
                            this.currentItem.workedwith = response2.data;
                            this.$forceUpdate();
                        });
                }

            }
        }
    }
</script>
