<template>
    <div class="container-fluid mt-4">

        <div class="row">

            <div class="col-12 col-lg-2">

                <h2 class="gallery-title">{{ $t('gallery.exhibitions') }}</h2>

                <div class="text-center w-100" v-if="null === albums">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>

                <ul class="gallery-links" v-if="albums">

                    <li v-for="item in albums" v-bind:key="item.id">
                        <a href="javascript:void(0)" :class="{ 'active' : currentAlbumId === item.id }"
                           @click="currentAlbumId = item.id">
                            <span>{{ item.name }}</span><span v-if="currentAlbumId === item.id"></span>
                        </a>
                    </li>

                </ul>

            </div>

            <div class="col-12 col-lg-9">

                <div class="text-center w-100" v-if="null === photos">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>

                <div class="row" v-if="currentAlbum">
                    <div class="col-12">
                        <h3 class="gallery-name">{{ $t('gallery.description_title').replace('{name}', currentAlbum.name) }}</h3>

                        <div class="gallery-description">
                            {{ currentAlbum.exhibition_desc }}
                        </div>

                        <h4 class="gallery-name mt-4">{{ $t('gallery.about_title').replace('{name}', currentAlbum.curator_name) }}</h4>

                        <div class="gallery-description mb-4">
                            {{ currentAlbum.curator_bio }}
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="text-center w-100 mt-5" v-if="photos && photos.length && photos.length !== items.length">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>

                    <div class="paragraph">
                        <v-photoswipe-gallery :isOpen="isOpenGallery" :options="optionsGallery" :items="items">

                            <img slot-scope="props" :src="props.item.src" alt="picture" class="col-12 col-lg-2">

                        </v-photoswipe-gallery>
                    </div>

                    <v-photoswipe :isOpen="isOpen" :items="items" :options="options" @close="hidePhotoSwipe"></v-photoswipe>

                </div>

            </div>

        </div>

    </div>
</template>

<style lang="scss" scoped>
    /* temp */
    .page-main-wrapper {
        height: 100%;
        font-family: 'Open Sans', sans-serif;
    }

    $grid-breakpoints: (
        // Extra small screen / phone
            xs: 0,
        // Small screen / phone
            sm: 576px,
        // Medium screen / tablet
            md: 768px,
        // Large screen / desktop
            lg: 992px,
        // Extra large screen / wide desktop
            xl: 1200px
    );

    .gallery-title {
        color: #ffffff;
        background: #5C3233;
        padding: 5px 15px;
        margin-left: 25px;
        font-size: 24px;
        font-weight: bold;

        @media screen and (max-width: map-get($grid-breakpoints, sm)) {
            margin-left: 0;
        }
    }

    .gallery-name {
        color: #ffffff;
        background: #5C3233;
        padding: 5px 15px;
        font-size: 24px;
        font-weight: bold;
    }

    .gallery-description {
        padding: 5px 15px;
        font-size: 14px;
    }

    .gallery-links {
        padding: 5px 15px;
        margin-left: 25px;
        font-weight: bold;

        @media screen and (max-width: map-get($grid-breakpoints, sm)) {
            margin-left: 0;
        }

        li {
            list-style-type: none;

            a {
                color: #aeaeae;
                font-size: 14px;

                -webkit-transition: all 200ms ease-in-out;
                -moz-transition: all 200ms ease-in-out;
                -o-transition: all 200ms ease-in-out;
                transition: all 200ms ease-in-out;

                &.active {
                    color: #ffffff;
                }

                span:first-child {
                    //text-decoration: underline;
                }

                span:last-child {
                    text-decoration: none;
                }

                &:hover {
                    color: #ffc103;
                    text-decoration: none;
                }
            }
        }
    }

    .gallery-img-thumb {
        position: relative;
        margin-bottom: 30px;
        opacity: 1;
        cursor: pointer;
        border: 1px solid #ffffff;

        //@media screen and (max-width: map-get($grid-breakpoints, sm)) {
        //    opacity: 0.5;
        //}

        -webkit-transition: all 200ms ease-in-out;
        -moz-transition: all 200ms ease-in-out;
        -o-transition: all 200ms ease-in-out;
        transition: all 200ms ease-in-out;

        &:hover {
            opacity: 0.8;
        }

        img {
            width: 100%;
        }

        .zoom-button {
            width: auto;
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }

    .custom-modal-img {
        width: 100%;
        border: 4px solid #ffffff;
    }

    .custom-modal-content {
        background-color: transparent;
    }

    .custom-modal-header {
        border-bottom: none;
        margin-top: -3rem;

        .close {
            cursor: pointer;
            opacity: 1;
            padding: 1rem 0;

            &:hover {
                opacity: 0.5;
            }
        }
    }

    .paragraph img {
        border: 1px solid #1f1b1b;
        background: #000000;
        padding: 10px;
        margin: 10px;

        -webkit-box-shadow: 0px 5px 5px 0px rgba(50, 50, 50, 0.75);
        -moz-box-shadow: 0px 5px 5px 0px rgba(50, 50, 50, 0.75);
        box-shadow: 0px 5px 5px 0px rgba(50, 50, 50, 0.75);

        @media screen and (max-width: map-get($grid-breakpoints, sm)) {
            padding: 10px;
            margin: 0;
        }
    }
</style>

<script>
    import axios from 'axios';
    import { PhotoSwipe, PhotoSwipeGallery } from 'v-photoswipe'

    export default {
        name: "gallery",
        components: {
            'v-photoswipe': PhotoSwipe,
            'v-photoswipe-gallery': PhotoSwipeGallery
        },
        methods: {
            hidePhotoSwipe () {
                this.isOpen = false
            }
        },
        mounted() {
            this.$appBgImage = '/bg/background.jpg';

            let that = this;
            axios
                .get(
                    this.apiBaseUrl + 'gallery/albums?format=json',
                    {
                        headers: {
                            'Accept-Language': that.$route.params.language === 'tr' ? 'tr' : 'en'
                        }
                    }
                )
                .then(response => {

                    this.albums = [];

                    for (let i = 0; i < response.data.length; i++) {
                        if (response.data[i].is_exhibition) {
                            this.albums.push(response.data[i]);
                        }
                    }

                    if (this.albums.length > 0) {
                        this.currentAlbumId = this.albums[0].id
                    }

                });
        },
        data() {
            return {
                photos: null,
                albums: null,
                currentAlbumId: null,
                currentAlbum: null,

                // PhotoSwipe
                isOpen: false,
                isOpenGallery: false,
                options: {
                    index: 0
                },
                optionsGallery: {
                    shareButtons: [
                        {
                            id: 'facebook',
                            label: 'Facebook',
                            url: 'https://www.facebook.com/sharer/sharer.php?u={{url}}'
                        },
                        {
                            id: 'twitter',
                            label: 'Twitter',
                            url: 'https://twitter.com/intent/tweet?text={{text}}&url={{url}}'
                        },
                        {
                            id: 'pinterest',
                            label: 'Pinterest',
                            url: 'http://www.pinterest.com/pin/create/button/?url={{url}}&media={{image_url}}&description={{text}}'
                        },
                    ],
                },
                items: []
            }
        },
        watch: {
            currentAlbumId: function () {

                if (null !== this.currentAlbumId) {
                    let that = this;
                    axios
                        .get(
                            this.apiBaseUrl + 'gallery/exhibition/' + this.currentAlbumId + '?format=json',
                            {
                                headers: {
                                    'Accept-Language': that.$route.params.language === 'tr' ? 'tr' : 'en'
                                }
                            }
                        )
                        .then(response => {

                            this.currentAlbum = response.data.album;
                            this.photos = response.data.pictures;

                            for (let i = 0; i < this.photos.length; i++) {
                                let img = new Image();
                                img.onload = function() {
                                    that.items.push({
                                        id: i,
                                        src: this.src,
                                        w: this.width,
                                        h: this.height,
                                        title: that.currentAlbum.name
                                    });
                                }
                                img.src = 'https://back.bluesdernegi.org/' + this.photos[i].picture;
                            }

                        });
                }

            }
        }
    }
</script>